import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { ToastrMessages } from '../../helpers/toaster.service';
import {
  NbCardModule,
  NbInputModule,
  NbSpinnerModule,
  NbButtonModule,
  NbSelectModule,
  NbRadioModule,
  NbCheckboxModule
} from '@nebular/theme';
@Component({
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  spinner = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toast: ToastrMessages,

  ) {
 
   ////////// if user is already login take to dashboard page ////////////////////
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      this.router.navigate(['/pages/dashboard']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  ////// ==========================  admin login function =========================== //////
  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.spinner = true;
    this.authService.login(this.f.email.value, this.f.password.value).subscribe(
      (response: any) => {
        this.spinner = false;

          this.router.navigate(['/pages/vehicle/add']);
          localStorage.setItem('authToken', response.body.authToken);
          localStorage.setItem('loginId', response.body.id);
          localStorage.setItem('userName', response.body.userName);
          localStorage.setItem('image', response.body.image);
      }
      ,
      (error) => {
        this.spinner = false;
        this.toast.showToast(error.error.message,'danger');

      });
  }
}
