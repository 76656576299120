import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { ToastrMessages } from '../../helpers/toaster.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  NbCardModule,
  NbInputModule,
  NbSpinnerModule,
  NbButtonModule,
  NbSelectModule,
  NbRadioModule,
  NbCheckboxModule
} from '@nebular/theme';
@Component({
  styleUrls: ['./forgot.component.scss'],
  templateUrl: './forgot.component.html',
})
export class ForgotComponent implements OnInit {
  forgotForm: FormGroup;
  submitted = false;
  spinner = false;
  baseUrl = environment.baseUrl;
  @ViewChild('form', {static: true}) form;
  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toast: ToastrMessages,
	private http: HttpClient

  ) {
 
   ////////// if user is already login take to dashboard page ////////////////////
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      this.router.navigate(['/pages/dashboard']);
    }
  }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.forgotForm.controls; }
  ////// ==========================  admin login function =========================== //////
  forgot() {
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return;
    }
    this.spinner = true;
	  const formData = new FormData();
      formData.append('email', this.f.email.value);
      this.http.post(`${this.baseUrl}forgotPasswordAdmin`, formData).subscribe(
        (response: any) => {
          this.toast.showToast(response.message,'success');
           //this.form.nativeElement.reset();
           //this.forgotForm.reset(this.initialState);
			
           // tslint:disable-next-line: forin
           // for (const i in this.forgotForm.controls) {
            //  this.forgotForm.controls[i].setErrors(null);
            //}
			this.spinner = false;
			this.router.navigate(['/auth']);
      },
      (error) => {
       
        this.toast.showToast(error.error.message,'danger');
      });
  }
}
