import {  Injectable } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';

import 'style-loader!angular2-toaster/toaster.css';
import {  NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
@Injectable({
    providedIn: 'root',
})
export class ToastrMessages {
    constructor(private toastrService: NbToastrService) { }

    config: ToasterConfig;

    destroyByClick = true;
    duration = 5000;
    hasIcon = true;
    position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    preventDuplicates = false;

    showToast(message,status,position=this.position) {
        this.toastrService.show(
          status || 'Success',
          `${message}`,
          { position, status });
      }
}
